<template>
  <div class="pricing-section-2">
    <div class="pricing-grid section-gap primary-layer">
      <div class="container">
        <!-- <div class="section-title-wrapper mb-5">
               <div class="row">
                  <div class="col-sm-12 col-md-9 mx-auto text-center">
                     <h2 class="mb-3 text-white">  </h2>
                  </div>
               </div>
        </div>-->

        <div class="section-title-wrapper">
          <div class="row">
            <div class="col-sm-12 col-md-6 mx-auto text-center">
              <h2 class="text-white">Plans and Pricing</h2>
              <p
                class="mb-0 text-white lead"
              >Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
            </div>
          </div>
        </div>
        <div class="row align-items-end">
          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 m-sm-b">
            <div class="pricing-wrap-plan">
              <div class="card">
                <div class="card-body">
                  <div class="p-name text-center mb-3">
                    <h4 class="text-green mb-3">Basic</h4>
                  </div>
                  <div class="p-price text-center">
                    <h2>$23</h2>
                  </div>
                  <div class="p-duration text-center font-weight-bold">Monthly</div>
                  <div class="p-icon text-center border-top mt-3">
                    <i class="icon ion-md-body font-4x text-primary"></i>
                  </div>
               
                  <div class="p-feature border-top border-bottom py-3">
                    <ul class="mb-0">
                      <li class="d-flex align-items-center justify-content-center">
                        <i class="icon ion-md-checkmark mr-2"></i>
                        <span>2 Gb Of Data</span>
                      </li>
                      <li class="d-flex align-items-center justify-content-center">
                        <i class="icon ion-md-checkmark mr-2"></i>
                        <span>20 Gb Of Cloud</span>
                      </li>
                      <li class="d-flex align-items-center justify-content-center">
                        <i class="icon ion-md-checkmark mr-2"></i>
                        <span>1Gb/S Speed</span>
                      </li>
                    </ul>
                  </div>
                  <div class="start-trail text-center pt-4">
                    <p class="font-weight-bold mb-3">
                      <a href="#" class="">Start Trail</a>
                    </p>
                    <a href="#" class="btn btn-primary text-white">Buy Now</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 m-sm-b">
            <div class="pricing-wrap-plan">
              <div class="card">
                <div class="card-body">
                  <div class="p-name text-center mb-3">
                    <h4 class="text-green mb-3">Simple</h4>
                  </div>
                  <div class="p-price text-center">
                    <h2>$52</h2>
                  </div>
                  <div class="p-duration text-center font-weight-bold">Monthly</div>
                  <div class="p-icon text-center border-top mt-3">
                    <i class="icon ion-md-bicycle font-4x text-primary"></i>
                  </div>
                  <div class="p-feature border-top border-bottom py-3">
                    <ul class="mb-0">
                      <li class="d-flex align-items-center justify-content-center">
                        <i class="icon ion-md-checkmark mr-2"></i>
                        <span>8 Gb Of Data</span>
                      </li>
                      <li class="d-flex align-items-center justify-content-center">
                        <i class="icon ion-md-checkmark mr-2"></i>
                        <span>36 Gb Of Cloud</span>
                      </li>
                      <li class="d-flex align-items-center justify-content-center">
                        <i class="icon ion-md-checkmark mr-2"></i>
                        <span>1Gb/S Speed</span>
                      </li>
                    </ul>
                  </div>
                  <div class="start-trail text-center pt-4">
                    <p class="font-weight-bold mb-3">
                      <a href="#">Start Trail</a>
                    </p>
                    <a href="#" class="btn btn-primary text-white">Buy Now</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 m-sm-b">
				
            <div class="pricing-wrap-plan most-picked-plan">
              <div class="card">
					  <div class="most-picked bg-success text-center py-3">
							 <h5 class="text-white mb-0">MOST PICKED</h5>
						</div> 
                <div class="card-body">
						 
                  <div class="p-name text-center mb-3">
                    <h4 class="text-green mb-3">Advanced</h4>
                  </div>
                  <div class="p-price text-center">
                    <h2>$125</h2>
                  </div>
                  <div class="p-duration text-center font-weight-bold">Monthly</div>
                  <div class="p-icon text-center border-top mt-3">
                    <i class="icon ion-md-car font-4x text-primary"></i>
                  </div>
               
                  <div class="p-feature border-top border-bottom py-3">
                    <ul class="mb-0">
                      <li class="d-flex align-items-center justify-content-center">
                        <i class="icon ion-md-checkmark mr-2"></i>
                        <span>50 Gb Of Data</span>
                      </li>
                      <li class="d-flex align-items-center justify-content-center">
                        <i class="icon ion-md-checkmark mr-2"></i>
                        <span>100 Gb Of Cloud</span>
                      </li>
                      <li class="d-flex align-items-center justify-content-center">
                        <i class="icon ion-md-checkmark mr-2"></i>
                        <span>1Gb/S Speed</span>
                      </li>
                    </ul>
                  </div>
                  <div class="start-trail text-center pt-4">
                    <p class="font-weight-bold mb-3">
                      <a href="#">Start Trail</a>
                    </p>
                    <a href="#" class="btn btn-primary">Buy Now</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 m-sm-b">
            <div class="pricing-wrap-plan">
              <div class="card">
                <div class="card-body">
                  <div class="p-name text-center mb-3">
                    <h4 class="text-green mb-3">Super</h4>
                  </div>
                  <div class="p-price text-center">
                    <h2>$290</h2>
                  </div>
                  <div class="p-duration text-center font-weight-bold">Monthly</div>
                  <div class="p-icon text-center border-top mt-3">
                    <i class="icon ion-md-airplane font-4x text-primary"></i>
                  </div>
              
                  <div class="p-feature border-top border-bottom py-3">
                    <ul class="mb-0">
                      <li class="d-flex align-items-center justify-content-center">
                        <i class="icon ion-md-checkmark mr-2"></i>
                        <span>1 TB Of Data</span>
                      </li>
                      <li class="d-flex align-items-center justify-content-center">
                        <i class="icon ion-md-checkmark mr-2"></i>
                        <span>5 TB Of Cloud</span>
                      </li>
                      <li class="d-flex align-items-center justify-content-center">
                        <i class="icon ion-md-checkmark mr-2"></i>
                        <span>1Gb/S Speed</span>
                      </li>
                    </ul>
                  </div>
                  <div class="start-trail text-center pt-4">
                    <p class="font-weight-bold mb-3">
                      <a href="#">Start Trail</a>
                    </p>
                    <a href="#" class="btn btn-primary">Buy Now</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import pricing from "Components/data/pricing.json";
export default {
  data() {
    return {
      pricing
    };
  }
};
</script>