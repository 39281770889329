<template>
  <div class="about-video section-gap">
    <div class="container">
      <div class="section-title-wrapper text-center">
        <h2>Let's Work Together</h2>
        <div class="row">
          <div class="col-sm-12 col-md-6 mx-auto text-center">
            <p
              class="mb-0 lead"
            >We design and develop software for funded startups and established companies. Here are just some examples.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="card border-0 shadow-md text-white border-rad">
        <img
          class="border-rad img-fluid"
          src="static/img/about-us.jpg"
          alt="Card image"
          width="1170"
          height="400"
        />
        <div class="card-img-overlay black-tp-layer pos-center text-center border-rad">
          <div class="center-holder">
            <a data-fancybox href="https://www.youtube.com/watch?v=hw2smSOJIv4" class="play-button">
              <i>
                <span class="btn-text">
                  <span>Play</span>
                </span>
                <span class="btn-icon">
                  <i class="fa fa-play"></i>
                </span>
              </i>
            </a>
          </div>
        </div>
      </div>

      <div class="row row-feature">
        <div class="col-md-4">
          <div class="media">
            <span class="mr-4 d-flex">
              <i class="font-4x text-info ion-ios-crop"></i>
            </span>
            <div class="media-body">
              <h4 class="mb-3">Fully Responsive</h4>
              <p class="mb-0">Fully responsive design on all browsers and mobiles devices without hiding any element.</p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="media">
            <span class="mr-4 d-flex">
              <i class="font-4x text-success ion-ios-color-wand"></i>
            </span>
            <div class="media-body">
              <h4 class="mb-3">30+Page Design</h4>
              <p class="mb-0">30+Beautifully designed page templates for your blog, portfolio, gallery and any other type of content.</p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="media">
            <span class="mr-4 d-flex">
              <i class="font-4x text-warning ion-ios-albums"></i>
            </span>
            <div class="media-body">
              <h4 class="mb-3">500+UI elements</h4>
              <p class="mb-0">Lots of UI elements and components gives you the power to build fully functional apps.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>